/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useCyberpunkPortfolio = () => {
  const query = useStaticQuery(graphql`
    query cyberpunkPortfolio {
      poster1: file(relativePath: { eq: "cyberpunk/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "cyberpunk/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "cyberpunk/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "cyberpunk/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "cyberpunk/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "cyberpunk/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "cyberpunk/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "cyberpunk/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Сергей Соборов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Фарид Давлетшин",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Дмитрий Рашдиов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Айза Жакина",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Владислав Зинченко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Павел Островлянчик",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Ксения Колсениченко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Мила Кулькова",
    },
  ];

  return data;
};

export default useCyberpunkPortfolio;
