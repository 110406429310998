// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/cyberpunk.jpg";
import ogImageVK from "assets/images/seo/vk/cyberpunk.jpg";
// data
import localData from "data/pages/cyberpunk";
// hooks
import useCyberpunkPortfolio from "hooks/portfolio/useCyberpunkPortfolio";
import useCyberpunkQuery from "hooks/graphql/useCyberpunkQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import MasterClassContent from "components/pages/course-page/master-class-content";

// Мастер-класс так и не запустили
const CyberpunkPage = () => {
  const prismicData = useCyberpunkQuery();
  const portfolio = useCyberpunkPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout logo="cyberpunk">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Киберпанк"
        description={
          seoData?.seodescription?.text ||
          "Мастер-класс по графической системе Киберпанк. В него входят: PDF-презентация, Закрытый телеграм-чат, Видеолекция, Видеоинструкция, Домашнее задание, индивидуальный разбор ДЗ, Вводный урок по InDesign, Макет в портфолио, Бонусный урок."
        }
        keywords={["киберпанк", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemCyberpunk)}
      />
      <MasterClassContent
        courseOffer={{ data }}
        courseExplanations={{ data: localData.CyberpunkExplanations }}
        courseTitle={data.coursetitle?.text}
        coursePortfolio={{
          pinterestText: "Киберпанк в Энциклопедии\nГрафдизайна Школы",
          pinterestLink: "https://www.pinterest.ru/vadim_granich/cyberpunk/",
          portfolio,
          graphicSystemName: "Киберпанк",
        }}
        coursePriceRange={{
          formId: "ltForm3153227",
          priceRange: localData.priceRange,
          formPostUrl:
            "https://school.granich.design/pl/lite/block-public/process-html?id=863550509",
        }}
      />
    </Layout>
  );
};

export default CyberpunkPage;
